import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { userLog } from "../../_api/index";
import { setSession, userEnroll } from "../../../redux-features/user/userSlice";
import SkillSection from "../details/SkillSection";
import Box from "@mui/material/Box";
import SkillSectionLayout from "../../../layouts/SkillSectionLayout";
import { useStyles } from "../../../utils/styles";
import { isStatic } from "../../common/isStatic";



export default function SkillAssess() {
    const dispatch = useDispatch();
    const skillSections = useSelector((state) => state.user.skillSections);
    const completedSubSkills = useSelector((state) => state.user.completedSubSkills)
    const subSkillId = useSelector((state) => state.user.subSkillId)
    const waitTime = useSelector(state => state.user.userData?.timer) || 0
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate();
    const classes = useStyles()
    const [assessContent, setAssessContent] = useState([]);
    const [nextContent, setNextContent] = useState();
    const [ind, setInd] = useState(0);
    const [nextTimer, setNextTimer] = useState(waitTime || 0);
    const [introContent, setIntroContent] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let temp = skillSections?.filter((ss) => {
            return ss?.name === "Assess";
        });
        let temp1 = skillSections?.filter((ss) => {
            return ss?.name === "SIMS";
        });
        let temp2 = skillSections?.filter((ss) => {
            return ss?.name === "Intro";
        });

        setNextContent(temp1);
        setAssessContent(temp);
        setIntroContent(temp2);
        if (temp.length === 0) {
            navigate("/skill-detail-sims");
        }
    }, [skillSections, ind]);

    const handleEnrollment = (data, index) => {
        if (assessContent.length - 1 == index) {
            if (nextContent?.length == 0) {
                dispatch(setSession(data));
                navigate("/skill-detail-quiz");
            }
            navigate("/skill-detail-sims");
        } else {
            dispatch(userEnroll(data));
            setNextTimer(waitTime);
            setInd(ind + 1)
        }
    };

    const allEnrolled = isStatic ? completedSubSkills?.includes(subSkillId) : assessContent.filter((content) => { return content.isCompleted }).length == assessContent.length

    const handleNextClick = () => {
        log({
            pageName: "Skill Details Assess",
            buttonName: "Next",
        });
        if (allEnrolled) {
            assessContent[ind + 1]
                ? setInd(ind + 1)
                : navigate("/skill-detail-sims");
        } else {
            handleEnrollment(assessContent[ind]?.id, ind);
        }
    }
    const handlePrevClick = () => {
        log({
            pageName: 'Skill Details Assess',
            buttonName: 'Prev'
        })
        if (ind <= 0) {
            if (!introContent.length) {
                window.history.go(-2)
            } else {
                window.history.go(-1)
            }
        } else {
            setInd(ind - 1)
        }

    }

    return (
        <SkillSectionLayout
            nextTimer={nextTimer}
            page="Skill Details Assess"
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
            isCompleted={allEnrolled}
        >
            <Box className={classes.skillSectionContainer}>
                <SkillSection content={assessContent[ind]} />
            </Box>
        </SkillSectionLayout>
    );
}
